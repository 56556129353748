import api from "@/api/index";
import store from "@/store";
import {
  setEmoji
} from "@/handlers/emoji";

export function getTransactions(that) {
  /*Получение и обработка списка транзакций*/
  that.empty = null;
  Object.keys(that.transactions).forEach((element) => {
    that.transactions[element] = null;
  });
  let url = "trn/list";
  that.loading = true;
  api
    .get(url, {
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        rows: that.rowsTrn,
        total_count: that.rowsTrn,
        page: that.pageTrn,
        company: that.companyTrn ?
          that.companyTrn :
          store.state.company.curCompany.id,
        group: that.groupTrn,
        card: that.filltres.cardNumber ? that.filltres.cardNumber : null,
        holder: that.filltres.holder ? that.filltres.holder : null,
        service: that.filltres.service ? that.filltres.service.trim() : null,
        handler_code: that.filltres.handlers ? that.filltres.handlers : null,
        card_group: that.filltres.card_group ? that.filltres.card_group : null,
        to_address: that.filltres.to_address ? that.filltres.to_address : null,
        group_to: that.filltres.group_to ? that.filltres.group_to : null,
        contract_num: that.filltres.contract_num ?
          that.filltres.contract_num :
          null,
        to_code: that.filltres.to_code ? that.filltres.to_code : null,
        amount_from: that.filltres.amount.from ?
          that.filltres.amount.from :
          null,
        amount_to: that.filltres.amount.to ? that.filltres.amount.to : null,
        price_from: that.filltres.price.from ? that.filltres.price.from : null,
        price_to: that.filltres.price.to ? that.filltres.price.to : null,
        sum_from: that.filltres.sum.from ? that.filltres.sum.from : null,
        sum_to: that.filltres.sum.to ? that.filltres.sum.to : null,

        sort: that.sort,
        desc: that.desc,
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );
      if (response.data.length !== 0) {
        that.totalRecords = response.data[0].total_count;
        // that.pageTrn = 1;
        // that.empty = false;
        response.data[0].total_count <= that.rowsTrn ?
          (that.isPaginator = false) :
          (that.isPaginator = true);
        let key = that.groupTrn !== null ? that.groupTrn : "all";
        that.transactions[key] = response.data;
      } else {
        Object.keys(that.transactions).forEach((element) => {
          that.transactions[element] = null;
        });
        that.isPaginator = false;
      }
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
      if (that.results) {
        api
          .get("trn/list", {
            params: {
              dateStart: that.dateStartTrn,
              dateEnd: that.dateEndTrn,
              rows: that.rowsTrn,
              page: that.pageTrn,
              company: that.companyTrn,
              card: that.cardTrn,
              group: "total",
              card: that.filltres.cardNumber ? that.filltres.cardNumber : null,
              holder: that.filltres.holder ? that.filltres.holder : null,
              service: that.filltres.service ?
                that.filltres.service.trim() :
                null,
              handler_code: that.filltres.handlers ?
                that.filltres.handlers :
                null,

              card_group: that.filltres.card_group ?
                that.filltres.card_group :
                null,
              to_address: that.filltres.to_address ?
                that.filltres.to_address :
                null,
              group_to: that.filltres.group_to ? that.filltres.group_to : null,
              contract_num: that.filltres.contract_num ?
                that.filltres.contract_num :
                null,
              to_code: that.filltres.to_code ? that.filltres.to_code : null,
              amount_from: that.filltres.amount.from ?
                that.filltres.amount.from :
                null,
              amount_to: that.filltres.amount.to ?
                that.filltres.amount.to :
                null,
              price_from: that.filltres.price.from ?
                that.filltres.price.from :
                null,
              price_to: that.filltres.price.to ? that.filltres.price.to : null,
              sum_from: that.filltres.sum.from ? that.filltres.sum.from : null,
              sum_to: that.filltres.sum.to ? that.filltres.sum.to : null,
            },
          })
          .then((response) => {
            that.transactionsListTotal = [];
            that.transactionsListTotal.push(response.data);
            // that.transactionsList = that.transactionsList.concat(that.transactionsListTotal)
          })
          .catch((err) => {
            console.log(err);
            let statusCode = err.request.status;
            let statusText = err.request.statusText;
            console.error(setEmoji("error") + statusCode + " " + statusText);
            switch (statusCode) {
              case 0:
                that.$toast.add({
                  severity: "warn",
                  summary: `${setEmoji("error")} Нет связи с сервером`,
                  detail: "Попробуйте позднее!",
                  life: 5000,
                });
                break;
              case 403:
                that.$toast.add({
                  severity: "warn",
                  summary: `${setEmoji("lock")} Время сессии истекло`,
                  detail: "Требуется авторизация",
                  life: 5000,
                });
                that.$router.push("/login");
                break;
              case 404:
                that.$toast.add({
                  severity: "error",
                  summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                  detail: "Запрос не найден",
                  life: 5000,
                });
                break;
              case 406:
                that.$toast.add({
                  severity: "error",
                  summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                  detail: "Укажите компании",
                  life: 3000,
                });
                break;
              default:
                that.$toast.add({
                  severity: "error",
                  summary: `${setEmoji(
                    "error"
                  )} Неизвестная ошибка ${statusCode}`,
                  detail: "Попробуйте позднее!",
                  life: 5000,
                });
                break;
            }
          });
      }
    });
}

export function getTransactionsChart(that) {
  /*Получение и обработка списка транзакций*/

  api
    .get("trn_v1/list", {
      params: {
        dateStart: that.dateStartTrn,
        dateEnd: that.dateEndTrn,
        rows: that.rowsTrn,
        total_count: that.rowsTrn,
        page: that.pageTrn,
        company: store.state.company.curCompany ?
          store.state.company.curCompany.id :
          null,
        group: that.groupTrn,
        card: that.cardTrn,
      },
    })
    .then((response) => {
      that.transactionsList = [];
      if (that.groupTrn === "card") {
        (that.transactionsListCard = response.data), (that.loading = false);
      } else if (that.groupTrn === "company") {
        (that.transactionsListCompany = response.data), (that.loading = false);
      } else if (that.groupTrn === "month") {
        (that.transactionsListMonth = response.data), (that.loading = false);
      } else if (that.groupTrn === "day") {
        that.transactionsListDay = null;
        that.basicData.datasets[0].data = [];
        that.basicData.labels = [];
        that.sumCount = [];
        that.setDaysLabel();
        if (response.data.length !== 0) {
          that.transactionsListDay = response.data.result;
          response.data.result.forEach((element) => {
            let a = +element.dt.split("-")[2] - 1;
            that.sumCount[a] = +element.sum;
          });
        }
        that.basicData.datasets[0].data = that.sumCount;
      } else if (that.groupTrn === "service") {
        that.chartData.datasets[0].data = [];
        that.amountCount = [];
        that.colors = [];
        that.chartData.labels = [];
        that.transactionsListService = response.data.result;
        response.data.result.forEach((element) => {
          that.chartData.labels.push(element.service_name);
        });
        response.data.result.forEach((element) => {
          that.amountCount.push(+element.amount);
          that.colors.push(
            "#" +
            (Math.random().toString(16) + "000000")
            .substring(2, 8)
            .toUpperCase()
          );
        });
        that.chartData.datasets[0].data = that.amountCount;
        that.chartData.datasets[0].backgroundColor = that.colors;
      } else {
        if (response.data.length !== 0) {
          that.transactionsList = response.data;
        }
        // that.totalRecords = response.data[0].total_count;
        that.loading = false;
      }
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      let emojiAngry = `${String.fromCodePoint(0x1f621)} `;
      let emojiSad = `${String.fromCodePoint(0x1f629)} `;
      console.error(emojiAngry + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${emojiSad} Нет связи с сервером ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${emojiSad} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${emojiAngry} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${emojiAngry} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}

export function getTransactionCurrentCard(that) {
  api
    .get("trn/list", {
      params: {
        dateStart: that.dateStartExpand,
        dateEnd: that.dateEndExpand,
        rows: that.rowsExpand,
        page: that.pageExpand,
        card: that.cardExpand,
        total_count: that.totalRecords,
        // totalRecords = response.data[0].total_count
      },
    })
    .then((response) => {
      response.data.result.forEach((i) => {
        that.cardTrnListExpand = response.data;
        that.totalRecordsExpand = response.data[0].total_count;
        that.loadingExpand = false;
      });
      response.data[0].total_count > that.rowsExpand ?
        (that.isPaginatorExpand = true) :
        (that.isPaginatorExpand = false);
      if (response.data.length === 0) {
        that.isPaginatorExpand = false;
      }
    })
    .catch((err) => {
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      let emojiAngry = `${String.fromCodePoint(0x1f621)} `;
      let emojiSad = `${String.fromCodePoint(0x1f629)} `;
      console.error(emojiAngry + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${emojiSad} Нет связи с сервером ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${emojiSad} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${emojiAngry} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${emojiAngry} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}
export function getHandlersList(that) {
  /*Получение и обработка списка транзакций*/

  api
    .get("handlers/list")
    .then((res) => {
      that.handlersList = res.data;
    })
    .catch((err) => {
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      let emojiAngry = `${String.fromCodePoint(0x1f621)} `;
      let emojiSad = `${String.fromCodePoint(0x1f629)} `;
      console.error(emojiAngry + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${emojiSad} Нет связи с сервером ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${emojiSad} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${emojiAngry} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${emojiAngry} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}